<template>
  <v-layout>
    <DatabaseAppBar
      :datasetIsSelected="selected"
      :searchTableIsActive="filterActive"
      @initHeaders="initialize"
      @filterChanged="setFilter"
      @resetFilters="resetFilter"
      @deleteSelectedDatasets="openDeleteDatasets"
      @createPDF="downloadPDF"
    />

    <v-data-table
      v-model="selected"
      calculate-widths
      class="pl-1"
      height="calc(100vh - 124px - 69px)"
      fixed-header
      hide-default-footer
      :items="filteredByProductline"
      item-key="Hersteller_ID"
      :items-per-page="100"
      :headers="datasetHeaders"
      :page.sync="page"
      :search="filter.search"
      @page-count="pageCount = $event"
      @dblclick:row="showDataset"
      :single-select="false"
      :show-select="checkCurrentUser"
      mobile-breakpoint="300"
      style="width: 100%;"
    >
      <template #[`header.Abkürzung`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchShort" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Abkürzung
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchShort" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Abkürzung
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchShort" dense outlined autofocus clearable hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Name`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchName" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Name
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchName" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Name
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchName" dense clearable autofocus outlined hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>
      <template #[`item.Name`]="{ item }">
        <span class="font-weight-bold">{{ item.Name }}</span>
      </template>

      <template #[`header.Kundennummer`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchClientnumber" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Kundennummer
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchClientnumber" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Kundennummer
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchClientnumber"
              dense
              clearable
              autofocus
              outlined
              hide-details
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Kontaktperson`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchContactperson" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Kontaktperson
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchContactperson" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Kontaktperson
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchContactperson"
              dense
              clearable
              autofocus
              outlined
              hide-details
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Telefon`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchTelephone" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Telefon
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchTelephone" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Telefon
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchTelephone" dense clearable outlined autofocus hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Email`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchEmail" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Email
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchEmail" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Email
                <v-icon color="red lighten-2" right>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchEmail" dense clearable outlined autofocus hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Sortiment`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchProductline" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Sortiment
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchProductline" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Sortiment
                <v-icon color="red lighten-2" right>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchProductline"
              dense
              clearable
              autofocus
              outlined
              hide-details
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template v-slot:no-data>
        <v-row dense align="center" justify="center" style="height: calc(100vh - 242px);">
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
          <v-col cols="8">
            <span>keine Daten gefunden - bitte Filter anpassen oder neue Daten erstellen</span>
          </v-col>
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-results>
        <v-row dense align="center" justify="center" style="height: calc(100vh - 242px);">
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
          <v-col cols="8">
            <span>keine Daten gefunden - bitte Filter anpassen oder neue Daten erstellen</span>
          </v-col>
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-footer app inset height="69" elevation="1">
      <v-col cols="12" class="pa-0">
        <v-row dense align="center" justify="start" class="px-1">
          <v-spacer></v-spacer>
          <v-col cols="8">
            <v-pagination color="grey darken-2" v-model="page" :length="pageCount"></v-pagination>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-footer>

    <DeleteDatasetsDialog ref="DeleteDatasets" @confirmDeleteDatasets="deleteDatasets" @abortDeleteDatasets="abortDeleteDatasets" />
  </v-layout>
</template>

<script>
import jsPDF from "jspdf";
import DatabaseAppBar from "../../../components/appbars/DatabaseAppBar.vue";
import DeleteDatasetsDialog from "../../../components/dialogs/DeleteDatasets.vue";

export default {
  components: {
    DatabaseAppBar,
    DeleteDatasetsDialog,
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.initialize();
  },

  data: () => ({
    filter: {
      search: "",
      placeholder: false,
    },
    tableSearch: {
      searchShort: null,
      searchName: null,
      searchClientnumber: null,
      searchContactperson: null,
      searchTelephone: null,
      searchEmail: null,
      searchProductline: null,
    },
    datasets: [],
    datasetHeaders: [],
    ausgeblendeteSpalten: [],
    hiddenColumns: [],
    page: 1,
    pageCount: 0,
    selected: [],
    DeleteDatasets: false,
  }),

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "Admin") {
        return true;
      } else return false;
    },

    filteredByPlaceholder() {
      if (this.datasets && !this.filter.placeholder) {
        return this.datasets.filter((item) => item.ist_aktiv === 1);
      } else if (this.datasets && this.filter.placeholder) {
        return this.datasets;
      }
    },

    filteredByShort() {
      if (this.tableSearch.searchShort) {
        return this.filteredByPlaceholder.filter((item) => item.Abkürzung != null && item.Abkürzung.includes(this.tableSearch.searchShort));
      } else return this.filteredByPlaceholder;
    },

    filteredByName() {
      if (this.tableSearch.searchName) {
        return this.filteredByShort.filter((item) => item.Name != null && item.Name.includes(this.tableSearch.searchName));
      } else return this.filteredByShort;
    },

    filteredByClientnumber() {
      if (this.tableSearch.searchClientnumber) {
        return this.filteredByName.filter((item) => item.Kundennummer != null && item.Kundennummer.includes(this.tableSearch.searchClientnumber));
      } else return this.filteredByName;
    },

    filteredByContactperson() {
      if (this.tableSearch.searchContactperson) {
        return this.filteredByClientnumber.filter(
          (item) => item.Kontaktperson != null && item.Kontaktperson.includes(this.tableSearch.searchContactperson)
        );
      } else return this.filteredByClientnumber;
    },

    filteredByTelephone() {
      if (this.tableSearch.searchTelephone) {
        return this.filteredByContactperson.filter((item) => item.Telefon != null && item.Telefon.includes(this.tableSearch.searchTelephone));
      } else return this.filteredByContactperson;
    },

    filteredByEmail() {
      if (this.tableSearch.searchEmail) {
        return this.filteredByTelephone.filter((item) => item.Email != null && item.Email.includes(this.tableSearch.searchEmail));
      } else return this.filteredByTelephone;
    },

    filteredByProductline() {
      if (this.tableSearch.searchProductline) {
        return this.filteredByEmail.filter((item) => item.Sortiment != null && item.Sortiment.includes(this.tableSearch.searchProductline));
      } else return this.filteredByEmail;
    },

    filterActive() {
      if (
        this.tableSearch.searchShort ||
        this.tableSearch.searchName ||
        this.tableSearch.searchClientnumber ||
        this.tableSearch.searchContactperson ||
        this.tableSearch.searchTelephone ||
        this.tableSearch.searchEmail ||
        this.tableSearch.searchProductline
      ) {
        return true;
      } else return false;
    },
  },

  methods: {
    setFilter(newfilter) {
      this.filter = Object.assign({}, newfilter);
    },

    resetFilter() {
      this.tableSearch.searchShort = null;
      this.tableSearch.searchName = null;
      this.tableSearch.searchClientnumber = null;
      this.tableSearch.searchContactperson = null;
      this.tableSearch.searchTelephone = null;
      this.tableSearch.searchEmail = null;
      this.tableSearch.searchProductline = null;
    },

    resetSelected() {
      this.selected = [];
    },

    showDataset(event, { item }) {
      this.$router.push({
        path: `/wws/database/${this.$route.meta.request}/dataset/show/${item.Hersteller_ID}`,
      });
    },

    async initialize() {
      const response = await fetch(`/api/database/${this.$route.meta.request}/tableheaders`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.hiddenColumns = this.ausgeblendeteSpalten.map((item) => item.Spalte);

      this.resetFilter();

      this.initializeContent();
    },

    async initializeContent() {
      const response = await fetch(`/api/database/${this.$route.meta.request}/datasets`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.datasetHeaders = Object.keys(this.datasets[0])
        .filter((it) => this.hiddenColumns.indexOf(it) === -1)
        .map((it, i) => {
          return {
            text: it,
            value: it,
            sortable: false,
            filterable: true,
            align: "center",
            divider: true,
            class: "text--primary text-uppercase",
          };
        });
    },

    //DIALOG ZUR BESTÄTIGUNG UM EINEN TERMIN ZU LÖSCHEN
    openDeleteDatasets() {
      this.$refs.DeleteDatasets.open();
    },

    //TERMIN LÖSCHEN WENN LÖSCHUNG BESTÄTIGT
    deleteDatasets() {
      const multipleDatasetDelete = this.selected;
      const amountDeleteDatasets = multipleDatasetDelete.length;
      const request = this.$route.meta.request;

      multipleDatasetDelete.forEach(async function(dataset) {
        const datasetToDelete = {};
        const datasetHistoryToDelete = {};

        if (Object.keys(datasetToDelete).length === 0) {
          Object.assign(datasetToDelete, {
            Artikel_ID: dataset.Artikel_ID,
          });

          await fetch(`/api/database/${request}/dataset/delete`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(datasetToDelete),
          });
        }

        if (Object.keys(datasetHistoryToDelete).length === 0) {
          Object.assign(datasetHistoryToDelete, {
            Artikel_ID: dataset.Artikel_ID,
          });

          await fetch(`/api/database/${request}/dataset/events/delete`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(datasetHistoryToDelete),
          });
        }
      });

      this.$root.actionSnackbar.show({
        amount: String(amountDeleteDatasets),
        dataset: "Artikel",
        action: "gelöscht",
      });
      this.DeleteDatasets = false;
      this.selected = [];
      this.initialize();
    },

    //LÖSCHEN ABBRECHEN
    abortDeleteDatasets() {
      this.DeleteDatasets = false;
    },

    downloadPDF() {
      var initialdata = this.filteredByProductline;

      initialdata.forEach(function(o) {
        Object.keys(o).forEach(function(key) {
          if (o[key] === null || o[key] === "") {
            o[key] = " ";
          }
        });
      });

      var data = initialdata;

      var initialheaders = this.datasetHeaders.map((item) => item.text);
      var headers = initialheaders;

      var dateandtime = new Date();
      var currentdate = dateandtime.toLocaleDateString("de-DE");

      if (headers.length > 4) {
        var doc = new jsPDF({
          orientation: "landscape",
          floatPrecision: "smart",
        });
        doc.table(1, 1, data, headers, {
          autoSize: true,
          fontSize: 10,
          padding: 2,
        });
        doc.save("Herstellerliste - Stand " + currentdate + ".pdf");
      } else if (headers.length <= 4) {
        var doc = new jsPDF({
          orientation: "portrait",
          floatPrecision: "smart",
        });
        doc.table(1, 1, data, headers, {
          autoSize: true,
          fontSize: 10,
          padding: 2,
        });
        doc.save("Herstellerliste - Stand " + currentdate + ".pdf");
      }

      this.initialize();
    },
  },
};
</script>
